<template>
<div class="expeiment-source">
  <div class="tabbar">
    <div :class="curSource.id == item.id ? 'active' : ''" v-for="item in list" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
  </div>
  <div class="expeiment-source-cont">
    <embed style="position: relative;z-index: 2" v-if="curSource.id == 3"  id="iframe" ref="myIframe" :src="curSource.list[0]?.source?.path" width="100%" height="100%" type="application/pdf" />
    <el-table
        :data="curSource.list"
        style="width: 100%"
        v-else
    >
      <el-table-column
          prop="id"
          label="ID"
          width="60">
      </el-table-column>
      <el-table-column
          prop="source.name"
          label="名称">
      </el-table-column>
      <el-table-column
          prop="address"
          label="操作"
          width="100">
      >
        <template slot-scope="scope">
<!--          1 视频-->
          <el-button type="text" v-if="curSource.id == 1" @click="checkVideo(scope.row)">查看</el-button>
          <el-button type="text" v-else @click="downLoad(scope.row.source.path)">下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
        :visible.sync="checkVideoModal"
        :destroy-on-close="true"
        size="80%">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">查看视频</div>
      </div>
      <div style="padding: 10px 20px; height: calc(100% - 60px);">
        <video ref="video" id="videoPlayer" :src="curData?.source?.path"  class="video-js vjs-default-skin" style="background-color: black;" controls width="100%" height="100%" controlslist="nodownload"></video>
      </div>
    </el-drawer>
  </div>
</div>
</template>

<script>
import FileSaver from "file-saver";
import util from '@/utils/tools.js';
export default {
  name: "index",
  data(){
    return{
      curSource:{},
      list:[],
      curData:{},
      checkVideoModal:false,
    }
  },
  props:{
    sourceGroup:{
      type:Array,
      default:()=>[]
    }
  },
  watch:{
    sourceGroup:{
      handler(newV){
        if(newV.length){
          this.list = JSON.parse(JSON.stringify(newV));
          this.list = this.list.filter((item)=>{
            return item.id != '7';
          })
          console.log(this.list);
          this.curSource = this.list[0];
        }
      },
      immediate:true,
    }
  },
  created(){

  },
  methods:{
    changeTab(data){
      this.curSource = data;
    },
    downLoad(data){
      // util.downloadFile(this,data);

      let arr = data.split('show?file=');
      console.log(arr,'ddddddd')
      if(arr[1]){
        let uploadParams = {
          file:arr[1]
        };
        console.log(uploadParams,'uploadParamsuploadParams')
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res);
        })
      }else{
        let name = data.split('/');
        name = name[name.length -1].split(/\.(?=[^\.]+$)/);
        name = name[0] + '.' + name[1];
        FileSaver.saveAs(data,name);
      }

    },
    checkVideo(data){
      this.curData = data;
      this.checkVideoModal = true;
    }
  }
}
</script>

<style scoped lang="scss">
.expeiment-source{
  height: 100%;
  .tabbar {
    border-bottom: 1px solid #dcdee2;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .tabbar > div {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
  }
  .tabbar .active {
    color: #4a79ff;
  }
  .tabbar .active::after {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background-color: #4a79ff;
    position: absolute;
    left: 0;
    bottom: -20px;
  }
  .expeiment-source-cont{
    height: calc(100% - 80px);
  }
}
</style>
