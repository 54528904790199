<template>
  <div class="program">
    <div class="program-header">
      <div class="header-left">
        <el-button icon="el-icon-arrow-left" size="small" @click="$router.back()">返回</el-button>
        <div class="header-left-name">{{experimentInfo.name}}</div>
      </div>
      <div class="header-right">
        <span v-if="experimentEndTime">请注意: 本次在线实验将在 {{ experimentEndTime }} 自动清除, 请合理安排时间!</span>
        <span v-else>请注意: 本次在线实验限时 {{ experimentInfo.timelong / 60 / 60 }} 小时 </span>
        <!-- <el-button icon="el-icon-tickets" size="small" @click="drawerShow = true">试题列表</el-button> -->
      </div>
    </div>
    <div class="program-main" v-loading="loading">
      <div class="left" ref="left">
<!--        <div style="position: absolute;width: 100%;height: 100%;left: 0;top: 0"></div>-->
<!--        <embed style="position: relative;z-index: 2" v-if="pdfFile?.source?.path"  id="iframe" ref="myIframe" :src="pdfFile?.source?.path" width="100%" height="100%" type="application/pdf" />-->
        <ExperimentSource v-if="sourceGroup.length" :sourceGroup="sourceGroup"></ExperimentSource>
        <div v-else style="text-align: center;font-size: 14px;">暂无数据</div>
      </div>
      <div class="line" ref="line" @mousedown="md">
        <i class="el-icon-more"></i>
      </div>
      <div class="right" ref="right">
        <div style="height: 100%" v-if="resourceList.length">
          <div class="right-tit">
            <div class="right-tit-txt">{{ experimentInfo.name}}</div>
            <div class="right-tit-refresh" @click="refresh">
              <i class="el-icon-refresh"></i> 刷新
            </div>
          </div>
          <el-alert style="margin-bottom: 20px;" :title="alertText" type="warning" />
          <div class="right-tab">
            <div :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
          </div>
          <div v-loading="resourceLoading" style="height: calc(100% - 130px);">
            <div class="environment-code" v-show="curTab.id == 1">
              <div class="environment-code-l">
                <div>文件</div>
                <div v-for="item in environmentCodeList" :key="item.name" @click="changeList(item)">
                  {{item.name}}
                </div>
              </div>
              <div class="environment-code-r">
                <div class="environment-code-r-name">{{curEnvironmentCode.name}}</div>
                <div ref="editor" class="editor"></div>
              </div>
            </div>
            <div v-if="curTab.id == 2">
              <el-table :data="resourceList" border v-loading="loading">
                <el-table-column prop="id" label="ID" width="100"></el-table-column>
                <el-table-column prop="name" label="标题"></el-table-column>
                <el-table-column label="状态">
                  <template v-slot="{ row }">
                    <div>{{ stackStatuses[row.ali_stack_status] }}</div>
                    <div>{{ row.ali_stack_id }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="创建时间">
                  <template v-slot="{ row }">
                    <div>{{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd') }}</div>
                  </template>
                </el-table-column>
              </el-table>
              <Pagin class="pagin" :pagination="pagination" @currentChange="currentChange" @sizeChange="sizeChange" />
            </div>
            <div v-if="curTab.id==3">
              <div class="right-link">
                <p>{{link}}</p>
                <el-button type="primary" @click="openLink()">打开链接</el-button>
              </div>
              <div class="right-videos">
                <p>请按照以下操作步骤进行实操</p>
                <video src="../../assets/images/op_video/experiment_op.mp4" autoplay muted controls loop></video>
              </div>
            </div>
          </div>

        </div>
        <div class="no-resourceList" v-else>
          <el-alert style="margin-bottom: 20px;" :title="alertText" type="warning" />
          <el-button type="primary" @click="createExperiment">创建实验</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/utils/tools.js";
import Pagin from "@/components/myPagin.vue";
import * as monaco from 'monaco-editor'
import ExperimentSource from "./components/experimentSource/index.vue";
export default {
  name: "ProgramView",
  data() {
    return {
      util,
      // 页面加载状态
      loading: true,
      position:{
        x:0,
        move:false,
      },
      experimentId:'',
      experimentInfo:{},
      keyword:'',
      resourceList:[],
      // 列表分页器数据
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      stackStatuses:{},
      pdfFile:{},
      environmentCode:{},
      environmentSource:{},
      environmentCodeList:[],
      // 编辑器实例
      editor: null,
      editOption: {
        value: "",
        // 编程语言
        language: "",
        // 字体大小
        fontSize: 16,
        // 自动布局
        automaticLayout: true,
        // 主题颜色
        theme: "vs",
        // 上下文菜单
        contextmenu: true,
        // 内联颜色
        colorDecorators: true,
        // 是否只读
        readOnly: true,
      },
      curEnvironmentCode:{},
      tabList:[
        {
          name:'部署信息',
          id:2
        },
        {
          name:'环境代码',
          id:1
        },
        {
          name:"打开阿里云平台",
          id:3
        }
      ],
      curTab:{
        name:'部署信息',
        id:2
      },
      link:"https://aliyun.com/",
      resourceLoading:false,
      experimentEndTime:'',
      sourceGroup:[],
      alertText:"第一步：点击创建实验；   第二步：在部署信息里，若状态为部署成功则可以开始实验操作"
    }
  },
  components:{
    Pagin,
    ExperimentSource
  },
  created() {
    this.experimentId = this.$route.query.id || '';
    this.getDetail();

  },
  mounted() {
    window.addEventListener("mousemove", this.mv);
    window.addEventListener("mouseup", this.mp);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.mv);
    window.removeEventListener("mouseup", this.mp);
  },
  methods: {
    // 拖动中间线条改变两侧大小
    md(e) {
      const { position } = this;
      position.x = e?.target?.offsetLeft || 0;
      position.move = true;
    },
    mv(e) {
      const { position } = this;
      if (!position.move) return;
      position.x = e.pageX;
      this.$refs.left.style.width = `${e.pageX}px`;
      this.$refs.right.style.width = "0px";
    },
    mp() {
      const { position } = this;
      position.move = false;
    },
    getDetail(){
      let params = {
        id:this.experimentId
      }
      this.loading = true;
      this.api.dataset.experimentDetail(params).then((res)=>{
        this.loading = false;
        this.experimentInfo = res.info;
        this.sourceGroup = res.source_group;
        let arr = this.experimentInfo.resource.filter((item)=>{
          return item.source_type === '3';
        });
        this.pdfFile = arr.length ? arr[0] : {};
        this.environmentSource= this.experimentInfo.resource.filter((item)=>{
          return item.source_type === '10';
        })[0];
        this.environmentCode = this.environmentSource?.source?.content ? JSON.parse(this.environmentSource.source.content) : {};
        this.environmentCodeList = [];
        for(let name in this.environmentCode.Workspace){
          this.environmentCodeList.push({
            name:name,
            value:this.environmentCode.Workspace[name]
          })
        }
        this.curEnvironmentCode = this.environmentCodeList.length ? this.environmentCodeList[0] : {};
        this.getInfo();


      }).catch((err)=>{
        this.loading = false;
      })
    },
    changeList(data){
      this.curEnvironmentCode = data;
      // 获取模型并设置新值
      const model = this.editor.getModel();
      model.setValue(data.value);
    },
    getInfo(){
      let params = {
        experiment_id:this.experimentId,
        keyword:this.keyword,
        page:this.pagination.page,
        page_size:this.pagination.pageSize
      };
      this.resourceLoading = true;
      this.api.dataset.aliyunForUser(params).then((res)=>{
        this.resourceLoading = false;
        this.resourceList = res.list;
        this.pagination.total = Number(res.count);
        this.experimentEndTime = util.timeFormatter(new Date(+ res.list[0].end_time * 1000), 'yyyy-MM-dd hh:mm');
        this.stackStatuses = res.stack_statuses;
        if(this.resourceList.length){
          this.$nextTick(()=>{
            if(!this.editor){
              this.initEditor();
            }


          })

        }

      }).catch((err)=>{
        this.resourceLoading = false;
      })
    },
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getInfo();
    },
    sizeChange(size) {
      const { pagination } = this;
      pagination.pageSize = size;
      this.getInfo();
    },
    refresh(){
      this.pagination = {
        page:1,
        pageSize: 10,
        total:0,
      };
      this.getInfo();
    },
    // 初始化编辑器
    initEditor() {
      const { editOption } = this;
      console.log(monaco.editor,this.$refs.editor,editOption,'monaco.editor')
      this.editor = monaco.editor.create(this.$refs.editor, editOption);
      console.log(this.editor,'this.editorthis.editor')
      // 编辑器赋值
      // 获取模型并设置新值
      const model = this.editor.getModel();
      if(this.environmentCodeList.length){
        model.setValue(this.environmentCodeList[0].value);
      }else{
        model.setValue('');
      }

    },
    changeTab(data){
      this.curTab = data;
    },
    createExperiment(){
      let params = {
        experiment_id:this.experimentId
      };
      this.api.dataset.aliyunOpen(params).then((res)=>{
        this.getInfo();
      })
    },
    openLink(){
      window.open(this.link,"_blank");
    }
  }
}
</script>

<style lang="scss" scoped>
.program {
  height: 100vh;
  overflow: hidden;
  background-color: white;
  font-size: 14px;

  .program-header {
    height: 60px;
    box-sizing: border-box;
    background-color: white;
    border-bottom: 10px solid #f7f8f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .header-left{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .header-left-name{
      margin: 0 20px ;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .program-main {
    height: calc(100% - 70px);
    display: flex;
    overflow: auto;

    .left {
      width: 40%;
      overflow-y: auto;
      padding: 20px;
      box-sizing: border-box;
      position: relative;

      .title {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .details {
        display: flex;
        color: #666;
        font-size: 16px;
        margin-bottom: 20px;

        .details-item {
          margin-right: 10px;
        }
      }

      //.knowledge-points {
      //  display: flex;
      //  align-items: center;
      //  margin-bottom: 20px;
      //
      //  .knowledge-points-title {
      //    font-size: 16px;
      //    font-weight: bold;
      //    color: #333;
      //  }
      //
      //  .knowledge-points-item {
      //    padding: 2px 5px;
      //    border-radius: 20px;
      //    color: #666;
      //    margin-left: 10px;
      //    background-color: #f7f8f9;
      //    border: 1px solid #e3e3e6
      //  }
      //}

      .describe {
        margin-bottom: 20px;

        .describe-title {
          font-size: 18px;
          color: #333;
          font-weight: bold;
        }

        .describe-desc {
          font-size: 16px;
        }
      }

      //.input {
      //  margin-bottom: 20px;
      //
      //  .input-title {
      //    font-size: 18px;
      //    color: #333;
      //    font-weight: bold;
      //    margin-bottom: 5px;
      //  }
      //
      //  .input-content {
      //    background-color: #f7f8f9;
      //    border-radius: 4px;
      //    padding: 20px;
      //    font-size: 16px;
      //  }
      //}

      //.output {
      //  margin-bottom: 20px;
      //
      //  .output-title {
      //    font-size: 18px;
      //    color: #333;
      //    font-weight: bold;
      //    margin-bottom: 5px;
      //  }
      //
      //  .output-content {
      //    background-color: #f7f8f9;
      //    border-radius: 4px;
      //    padding: 20px;
      //    font-size: 16px;
      //  }
      //}
    }

    .line {
      width: 10px;
      transition: .3s;
      background-color: #f7f8f9;
      cursor: move;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #686868;

      i {
        transform: rotate(90deg);
      }

      &:hover {
        background-color: #dfdfdf;
      }
    }

    .right {
      padding: 20px;
      flex: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      .right-tit{
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        >div{
          margin-right: 40px;
        }
        .right-tit-txt{
          font-size: 18px;
          font-weight: bold;
        }
        .right-tit-refresh{
          color:#4E83FF;
          cursor: pointer;
        }
      }
      .right-tab{
        margin-bottom: 20px;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        >div{
          margin-right: 30px;
          cursor: pointer;
        }
        .active{
          border-bottom: 2px solid #4E83FF;
          font-weight: bold;
        }
      }
      .environment-code{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 16px;
        border: 1px solid #EEEEEE;
        height: 100%;
        .environment-code-l{
          width:200px;
          border-right: 1px solid #EEEEEE;
          height: 100%;
          >div:first-child{
            border-bottom: 1px solid #EEEEEE;
          }

          >div{
            padding: 0 20px;
            line-height: 40px;
            cursor: pointer;
          }
        }
        .environment-code-r{
          flex: 1;
          width: 0;
          height: 100%;
          .environment-code-r-name{
            padding: 0 20px;
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid #EEEEEE;
          }
          .editor{
            height: calc(100% - 40px);
          }
        }
      }
      .no-resourceList{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .right-link{
        width: 100%;
        padding: 0 20px;
        height: 81px;
        background: #F8F8F9;
        border: 1px solid #EEEEEE;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666666;
        font-size: 14px;
        >p{
          flex-shrink: 1;
          word-break: break-all;
          white-space: wrap;
        }
      }
      .right-videos{
        text-align: center;
        >p{
          margin-top: 100px;
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
        >video{
          width: 493px;
          height: 328px;
        }
      }
    }
  }
}
</style>
